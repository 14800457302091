import { BaseService } from './BaseService';
import { gameDataService } from './GameDataService';


interface ICredentials {
    URL: string;
}

class WebAPIService extends BaseService {
    public setCredentials (credentials: ICredentials): void {
        this._credentials = credentials;
    }

    protected async getHeaders (): Promise<any> {
        const webToken = await gameDataService.webToken;
        return { Authorization: `Bearer ${ webToken ? webToken : '' }` };
    }

    public async updateRRP (body: { interested_IQOS?: number; interested_lil?: number }) {
        const route = '/users/updateRRP';
        return this.post(route, body);
    }
}

export const WebService = new WebAPIService();
