export const STATIC_IDS = {
    MARLBORO          : process.env.REACT_APP_MARLBORO_ID || '',
    SUMMER_QUIZ       : process.env.REACT_APP_SUMMER_QUIZ_ID || '',
    TEST_QUIZ         : process.env.REACT_APP_TEST_QUIZ_ID || '',
    PARLIAMENT        : process.env.REACT_APP_PARLIAMENT_ID || '',
    INTERACTIVE_GAME  : process.env.REACT_APP_INTERACTIVE_GAME_ID || '',
    COUNTRIES         : process.env.REACT_APP_COUNTRIES_ID || '',
    ARCHETYPE         : process.env.REACT_APP_ARCHETYPE_ID || '',
    IQOS              : process.env.REACT_APP_IQOS_ID || '',
    WINTER_SHUFFLE    : process.env.REACT_APP_WINTER_SHUFFLE_ID || '',
    WINTER_SHUFFLE_MB : process.env.REACT_APP_WINTER_SHUFFLE_MB_ID || '',
    LOYALTY_PARLIAMENT: process.env.REACT_APP_LOYALTY_PARLIAMENT_ID || '',
    LOYALTY_LM        : process.env.REACT_APP_LOYALTY_LM_ID || '',
    LOYALTY_MPM       : process.env.REACT_APP_LOYALTY_MPM_ID || '',
    LOYALTY_MARLBORO  : process.env.REACT_APP_LOYALTY_MARLBORO_ID || '',
    GOOD_LUCK         : process.env.REACT_APP_GOOD_LUCK_ID || '',
};

export const IDS = [
    // local
    '675b122aa59e7c61ff9ab7d0',
    '6744e44ca59e7c61ff9ab7cf',

    //stage
    '66426b34c9b7015d11bfe6d6',
    '633339d8fa5ab94f9cd8bbef',
    '6368c0fefa5ab94f9cd8bc1d',
    '633711dafa5ab94f9cd8bbf5',
    '63370a62fa5ab94f9cd8bbf4',
    '63359152fa5ab94f9cd8bbf3',
    '63358e6ffa5ab94f9cd8bbf2',
    '63350f07fa5ab94f9cd8bbf1',

    // prod
    '673f0c190b349574baccbbbf',
    '673f0c130b349574baccbbbe',
    '673f0c0d0b349574baccbbbd',
    '673f0c060b349574baccbbbc',
    '673f0c000b349574baccbbbb',
    '673f0bfc0b349574baccbbba',
    '673f0bf20b349574baccbbb9',
    '673f0beb0b349574baccbbb8',
    '673f0be60b349574baccbbb7',
    '673f0bde0b349574baccbbb6',
    '673f0bd80b349574baccbbb5',
    '673f0bcc0b349574baccbbb4',
    '673f0bc60b349574baccbbb3',
    '673f0bc00b349574baccbbb2',
    '673f0bba0b349574baccbbb1',
    '673f0bb50b349574baccbbb0',
    '673f0ba80b349574baccbbaf',
    '673f0ba00b349574baccbbae',
    '673f0b970b349574baccbbad',
    '673f0b900b349574baccbbac',
    '66fe5c1a0b349574baccbbab',
    '66fe5c130b349574baccbbaa',
    '66fe5c0c0b349574baccbba9',
    '66fe5c050b349574baccbba8',
    '66fe5bcf0b349574baccbba7',
    '66fe5bc70b349574baccbba6',
    '66fe5bbe0b349574baccbba5',
    '66fe5bb60b349574baccbba4',
    '66fe5bae0b349574baccbba3',
    '66fe5ba80b349574baccbba2',
    '66fe5ba10b349574baccbba1',
    '66fe5b9b0b349574baccbba0',
    '66fe5b930b349574baccbb9f',
    '66fe5b8c0b349574baccbb9e',
    '66fe5b860b349574baccbb9d',
    '66fe5b810b349574baccbb9c',
    '66fe5b790b349574baccbb9b',
    '66fe5b720b349574baccbb9a',
    '66fe5b6c0b349574baccbb99',
    '66fe5b650b349574baccbb98',
    '66fe5b580b349574baccbb97',
    '66fe5b520b349574baccbb96',
    '66fe5b4b0b349574baccbb95',
    '66fe5b430b349574baccbb94',
    '66fe5b3b0b349574baccbb93',
    '66f13cfc0b349574baccbb92',
    '66f13cf60b349574baccbb91',
    '66f13cef0b349574baccbb90',
    '66f13ce90b349574baccbb8f',
    '66f13ce20b349574baccbb8e',
    '66f13cdc0b349574baccbb8d',
    '66f13cd10b349574baccbb8c',
    '66f13cc90b349574baccbb8b',
    '66f13cc00b349574baccbb8a',
    '66f13cb90b349574baccbb89',
    '66f13cb20b349574baccbb88',
    '66f13caa0b349574baccbb87',
    '66f13ca30b349574baccbb86',
    '66f13c9b0b349574baccbb85',
    '66f13bf30b349574baccbb84',
    '66c6f8030b349574baccbb83',
    '66c6f7fa0b349574baccbb82',
    '66c6f7f30b349574baccbb81',
    '66c6f7ec0b349574baccbb80',
    '66c6f7e50b349574baccbb7f',
    '66c6f7dd0b349574baccbb7e',
    '66c6f7c40b349574baccbb7d',
    '66c6f7b40b349574baccbb7c',
    '66c6f79c0b349574baccbb7b',
    '66c6f7890b349574baccbb7a',
    '66c6f76d0b349574baccbb79',
    '66c6f7600b349574baccbb78',
    '66c6f74f0b349574baccbb77',
    '66c6f7460b349574baccbb76',
    '66c6f73b0b349574baccbb75',
    '669515280b349574baccbb74',
    '6695151e0b349574baccbb73',
    '669515150b349574baccbb72',
    '6695150b0b349574baccbb71',
    '669515010b349574baccbb70',
    '669514f90b349574baccbb6f',
    '669514f20b349574baccbb6e',
    '669514e90b349574baccbb6d',
    '669514e00b349574baccbb6c',
    '669514d80b349574baccbb6b',
    '669514ce0b349574baccbb6a',
    '669514c00b349574baccbb69',
    '669514b90b349574baccbb68',
    '669514b10b349574baccbb67',
    '669514a80b349574baccbb66',
    '6695149c0b349574baccbb65',
    '667168d70b349574baccbb60',
    '667168cb0b349574baccbb5f',
    '667168c10b349574baccbb5e',
    '667168b10b349574baccbb5d',
    '667168a40b349574baccbb5c',
    '6671689b0b349574baccbb5b',
    '667168910b349574baccbb5a',
    '667168870b349574baccbb59',
    '6671687e0b349574baccbb58',
    '667168730b349574baccbb57',
    '6634d5440b349574baccbb52',
    '6634d53c0b349574baccbb51',
    '6634d5340b349574baccbb50',
    '6634d5280b349574baccbb4f',
    '6634d51f0b349574baccbb4e',
    '6634d5130b349574baccbb4d',
    '6634d50a0b349574baccbb4c',
    '6634d5030b349574baccbb4b',
    '6634d4f70b349574baccbb4a',
    '6634d4ee0b349574baccbb49',
    '6634d4e50b349574baccbb48',
    '6634d4db0b349574baccbb47',
    '6628df720b349574baccbb46',
    '6628df5f0b349574baccbb45',
    '6628df3d0b349574baccbb44',
    '6628df280b349574baccbb43',
    '6628df130b349574baccbb42',
    '6628def90b349574baccbb41',
    '66016af30b349574baccbb40',
    '649d48b5f3fa13977db2c413',
    '649d48b0f3fa13977db2c412',
    '649d48acf3fa13977db2c411',
    '649d48a7f3fa13977db2c410',
    '649d48a1f3fa13977db2c40f',
    '649d489cf3fa13977db2c40e',
    '649d4897f3fa13977db2c40d',
    '649d4891f3fa13977db2c40c',
    '649d488cf3fa13977db2c40b',
    '649d4886f3fa13977db2c40a',
    '649d4881f3fa13977db2c409',
    '649d487cf3fa13977db2c408',
    '649d4876f3fa13977db2c407',
    '63692ac1f3fa13977db2a48a',
    '6369242ff3fa13977db2a47c',
    '6369f85cf3fa13977db2a4b7',
    '6337f912f3fa13977db29f5f',
    '6337f907f3fa13977db29f5e',
    '6337f8fdf3fa13977db29f5d',
    '6369f84cf3fa13977db2a4b6',
    '636a00eef3fa13977db2a4bb',
    '6337f8ddf3fa13977db29f5a',
    '6337f8d3f3fa13977db29f59',
    '6369ed73f3fa13977db2a4ac',
    '6369f566f3fa13977db2a4ad',
    '633698cef3fa13977db29f48',
    '633694e8f3fa13977db29f47',
    '63361ebff3fa13977db29f38',
];

export const IQOS_IDS = [
    '64160e28fa5ab94f9cd8bc25',
    '6416dd4efa5ab94f9cd8bc26',
    '6416dd5bfa5ab94f9cd8bc27',

    '6424425cf3fa13977db2c00e',
    '64244261f3fa13977db2c00f',
    '64244270f3fa13977db2c010',
    '64244278f3fa13977db2c011',
    '6424427df3fa13977db2c012',
    '64244282f3fa13977db2c013',
    '64244287f3fa13977db2c014',
    '6424428bf3fa13977db2c015',
    '64244290f3fa13977db2c016',
    '64244298f3fa13977db2c017',
];
