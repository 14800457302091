import axios, { AxiosResponse } from 'axios';
import { gameDataService } from './GameDataService';


interface ICredentials {
    URL: string;
}

axios.interceptors.response.use(
    (res) => res,
    (error) => {
        try {
            const response     = error.response;
            const status       = response && response.status;
            const data         = response && response.data;
            const errorMessage = data && data.detail;

            if (status === 401 && errorMessage === 'Invalid token.') {
                return Promise.reject(new Error('Token has expired'));
            }

            return Promise.reject(error);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log('🚀 ~ file: BaseService.ts', e);
        }
    }
);

export class BaseService {
    protected _credentials?: ICredentials;

    protected _prefix: string = '';

    public setCredentials (credentials: ICredentials): void {
        this._credentials = credentials;
    }

    protected getCurrentUrl (path: string): string {
        if (this.credentials) {
            return `${ this.credentials.URL }${ this.prefix }${ path }`;
        }

        return `${ this.prefix }${ path }`;
    }

    protected get credentials () {
        return this._credentials;
    }

    protected async getHeaders (): Promise<any> {
        const gameToken = await gameDataService.gameToken;
        return { Authorization: `Bearer ${ gameToken ? gameToken : '' }` };
    }

    public set prefix (prefix: string | undefined) {
        this._prefix = prefix || '';
    }

    public get prefix (): string | undefined {
        return this._prefix;
    }

    public async get <T> (route: string, params?: any): Promise<AxiosResponse<T>> {
        const url: string = this.getCurrentUrl(route);
        const headers = await this.getHeaders();
        return await axios.get(url, { headers, params });
    }

    public async post <T> (route: string, data: any, customHeaders = {}): Promise<AxiosResponse<T>> {
        const url: string = this.getCurrentUrl(route);
        const headers = await this.getHeaders();
        return await axios.post(url, data, { headers: { ...headers, ...customHeaders } });
    }

    public async put (route: string, data: any) {
        const url: string = this.getCurrentUrl(route);
        const headers = await this.getHeaders();
        return await axios.put(url, data, { headers });
    }

    public async patch (route: string, data: any) {
        const url: string = this.getCurrentUrl(route);
        const headers = await this.getHeaders();
        return await axios.patch(url, data, { headers });
    }

    public async remove (route: string) {
        const url: string = this.getCurrentUrl(route);
        const headers = await this.getHeaders();
        return await axios.delete(url, { headers });
    }
}
