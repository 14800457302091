import { BaseService } from './BaseService';
import { gameDataService } from './GameDataService';


class AuthAPIService extends BaseService {
    public async saveOnlyTokenID (gameId: string, token: string) {
        await gameDataService.saveGameId(gameId);
        await gameDataService.saveGameToken(token);
    }

    public async clearGameData () {
        await gameDataService.clearGameData();
    }
}

export const AuthService = new AuthAPIService();
