/* eslint-disable no-unused-vars */
import { BreadcrumbLoggerInterface } from 'shared/lib/sentry/breadcrumb.logger.interface';


export abstract class BrowserStorageAbstract {
    storageType: string = '';

    protected constructor (
        private readonly _storage: Storage,
        private readonly _breadcrumbLogger: BreadcrumbLoggerInterface
    ) {}

    getItem<T = any> (key: string): T | null {
        try {
            const data = this._storage.getItem(key);
            this.logBreadcrumb(`Get item "${ key }" from ${this.storageType} storage`, { key, data });

            try {
                return data ? JSON.parse(data) : data;
            } catch (error) {
                console.warn(error);
                return data as (T | null);
            }
        } catch (error) {
            console.warn(`Failed to get item "${ key }" from ${this.storageType} storage`, error);
            return null;
        }
    }

    removeItem (key: string): void {
        this.logBreadcrumb(`Remove item "${ key }" from ${this.storageType} storage`, { key });

        try {
            return this._storage.removeItem(key);
        } catch (error) {
            console.warn(error);
        }
    }

    setItem<T = any> (key: string, value: T): void {
        this.logBreadcrumb(`Set item "${ key }" to ${this.storageType} storage`, { key, data: value });

        try {
            const data = JSON.stringify(value);
            return this._storage.setItem(key, data);
        } catch (error) {
            console.warn(`Failed to set item "${ key }" to ${this.storageType} storage`, value, error);
        }
    }

    protected logBreadcrumb (message: string, data: Record<string, any>): void {
        this._breadcrumbLogger.addBreadcrumb({
            message,
            data,
            category: 'storage',
            level: 'info',
        });
    }
}
