import { hot } from 'react-hot-loader/root';
import * as Sentry from '@sentry/react';
import { captureConsoleIntegration } from '@sentry/integrations';
import { beforeSend } from 'shared/lib/sentry/before-send.sentry';
import { Router } from './Router';
import { GameService, WebService, MetricsService } from 'services';


GameService.setCredentials({ URL: process.env.REACT_APP_API_URL || '' });
GameService.prefix = 'gameflows';
WebService.setCredentials({ URL: process.env.REACT_APP_WEB_URL || '' });
MetricsService.setCredentials({ URL: process.env.REACT_APP_METRICS_URL || '' });


Sentry.init({
    release     : process.env.REACT_APP_VERSION,
    dsn         : 'https://5afe3e26e239f96424ed6709a823460b@o4508301841793024.ingest.de.sentry.io/4508301847101520',
    integrations: [
        captureConsoleIntegration({
            levels: [ 'error' ],
        }),
        new Sentry.BrowserTracing({
            tracePropagationTargets: [ /^https:\/\/pmi-game\.alvs\.tech/ ],
        }),
    ],
    tracesSampleRate: 1.0,
    beforeSend,
});


export const Root = hot(Router);
