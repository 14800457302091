export const IS_DEVELOPMENT                                  = process.env.NODE_ENV === 'development';
export const GOOD_LUCK_BASE_URL                              = '/goodluck';
export const GOOD_LUCK_MAX_CONTENT_ACTIVITIES_NUMBER         = 'max.activities.number';
export const GOOD_LUCK_MAX_CONTENT_ACTIVITIES_NUMBER_DEFAULT = 16;
export const GOOD_LUCK_MAX_SPINS_NUMBER                      = 'max.spins.number';
export const GOOD_LUCK_MAX_SPINS_NUMBER_DEFAULT              = 48;
export const GOOD_LUCK_GAME_IS_ACTIVE                        = 'game.is.active';
export const GOOD_LUCK_GAME_IS_ACTIVE_DEFAULT                = true;
export const GOOD_LUCK_GAME_IS_NOT_ACTIVE_START_PAGE         = 'game.is.not.active.start.page';
export const GOOD_LUCK_GAME_IS_NOT_ACTIVE_START_PAGE_DEFAULT = '/complete';

export const MINI_ENABLED = 'mini.enabled';
export const MINI_ENABLED_DEFAULT = false;

export const STATIC_URL = 'https://pmi-static.alvs.tech';
