/* eslint-disable no-unused-vars */
import { browserCookieStorage, browserLocalStorage, browserSessionStorage, BrowserStorageAbstract } from 'entities/browser-storage';
import { cloudStorage, CloudStorageInterface } from 'entities/cloud-storage';
import { parseJwt } from 'shared/lib/parse-jwt';


const GAME_TOKEN_KEY    = 'game_token';
const WEB_TOKEN_KEY     = 'web_token';
const REDIRECT_URL_KEY  = 'redirect_url';
const USER_ID_KEY       = 'user_id';
const CITY_KEY          = 'city';
const GAME_ID_KEY       = 'game_id';
const TSA_HASH_KEY      = 'tsahash';
const SESSION_TOKEN_KEY = 'session_token';
const FROM_ACTIVITY_KEY = 'from_activity';


export class GameDataService {
    constructor (
        private readonly _sessionStorage: BrowserStorageAbstract,
        private readonly _localStorage: BrowserStorageAbstract,
        private readonly _cookieStorage: BrowserStorageAbstract,
        private readonly _cloudStorage: CloudStorageInterface
    ) {}

    async saveGameData () {
        await this.saveAll();
        return true;
    }

    clearGameData () {
        return Promise.allSettled([
            this.removeItem(GAME_TOKEN_KEY),
            this.removeItem(WEB_TOKEN_KEY),
            this.removeItem(REDIRECT_URL_KEY),
            this.removeItem(USER_ID_KEY),
            this.removeItem(CITY_KEY),
            this.removeItem(GAME_ID_KEY),
            this.removeItem(TSA_HASH_KEY),
            this.removeItem(SESSION_TOKEN_KEY),
        ]);
    }

    async saveGameToken (passedGameToken?: string | null) {
        if (passedGameToken) {
            await this.setItem(GAME_TOKEN_KEY, passedGameToken);
            return this.gameToken;
        }

        const gameToken = this.getParamsGameToken();

        if (!gameToken) return this.gameToken;

        await this.setItem(GAME_TOKEN_KEY, gameToken);

        return this.gameToken;
    }

    async saveGameId (passedGameId?: string | null) {
        if (passedGameId) {
            await this.setItem(GAME_ID_KEY, passedGameId);
            return this.gameId;
        }

        const gameId = this.getPathnameGameId();

        if (!gameId) return this.gameId;

        await this.setItem(GAME_ID_KEY, gameId);

        return this.gameId;
    }


    get sessionToken () {
        return this.getItem<string>(SESSION_TOKEN_KEY);
    }

    get tsaHash () {
        return this.getItem<string>(TSA_HASH_KEY);
    }

    get gameToken () {
        return this.getItem<string>(GAME_TOKEN_KEY);
    }

    get webToken () {
        return this.getItem<string>(WEB_TOKEN_KEY);
    }

    get redirectUrl () {
        return this.getItem<string>(REDIRECT_URL_KEY);
    }

    get userId () {
        return this.getItem<string>(USER_ID_KEY);
    }

    get city () {
        return this.getItem<string>(CITY_KEY);
    }

    get fromActivity () {
        return this.getParamsFromActivity();
    }

    get gameId () {
        return this.getItem<string>(GAME_ID_KEY);
    }

    private saveAll () {
        return Promise.allSettled([
            this.saveGameToken(),
            this.saveWebToken(),
            this.saveRedirectUrl(),
            this.saveUserId(),
            this.saveCity(),
            this.saveGameId(),
            this.saveTSAHash(),
            this.saveSessionToken(),
        ]);
    }

    private async saveWebToken () {
        const webToken = this.getParamsWebToken();

        if (!webToken) return this.webToken;

        await this.setItem(WEB_TOKEN_KEY, webToken);

        return this.webToken;
    }

    private async saveRedirectUrl (passedRedirectUrl?: string | null) {
        if (passedRedirectUrl) {
            await this.setItem(REDIRECT_URL_KEY, passedRedirectUrl);
            return this.redirectUrl;
        }

        const redirectUrl = this.getParamsRedirectUrl();

        if (!redirectUrl) return this.redirectUrl;

        await this.setItem(REDIRECT_URL_KEY, redirectUrl);

        return this.redirectUrl;
    }

    private async saveUserId () {
        const userId = this.getParamsUserId();

        if (!userId) return this.userId;

        await this.setItem(USER_ID_KEY, userId);

        return this.userId;
    }

    private async saveCity () {
        const city = this.getParamsCity();

        if (!city) return this.city;

        await this.setItem(CITY_KEY, city);

        return this.city;
    }

    private async saveTSAHash () {
        const TSAHash = this.getParamsTSAHash();

        if (!TSAHash) return this.tsaHash;

        await this.setItem(TSA_HASH_KEY, TSAHash);

        return this.tsaHash;
    }

    private async saveSessionToken () {
        const sessionToken = this.getParamsSessionToken();

        if (!sessionToken) return this.sessionToken;

        await this.setItem(SESSION_TOKEN_KEY, sessionToken);

        return this.sessionToken;
    }

    private getParamsSessionToken () {
        return this.params.get(SESSION_TOKEN_KEY);
    }

    private getParamsTSAHash () {
        return this.params.get(TSA_HASH_KEY);
    }

    private getPathnameGameId () {
        const gameIdMatch = window.location.pathname.match(/\/([a-z0-9]{24})/);
        return gameIdMatch && gameIdMatch[1] || '';
    }

    private getParamsFromActivity () {
        return this.params.get(FROM_ACTIVITY_KEY);
    }

    private getParamsCity () {
        return this.params.get(CITY_KEY);
    }

    private getParamsUserId () {
        const gameToken = this.getParamsGameToken();
        const { gamer } = gameToken && parseJwt(gameToken) || {};
        return gamer?.user_id;
    }

    private getParamsRedirectUrl () {
        return this.params.get(REDIRECT_URL_KEY);
    }

    private getParamsGameToken () {
        return this.params.get(GAME_TOKEN_KEY);
    }

    private getParamsWebToken () {
        return this.params.get(WEB_TOKEN_KEY)?.replace(/ /g, '+');
    }

    private get params () {
        return new URLSearchParams(window.location.search);
    }

    private async setItem (key: string, value: string) {
        this._sessionStorage.setItem(key, value);
        this._localStorage.setItem(key, value);
        this._cookieStorage.setItem(key, value);
        this._cloudStorage.setItem(key, value);
    }

    private async removeItem (key: string) {
        this._sessionStorage.removeItem(key);
        this._localStorage.removeItem(key);
        this._cookieStorage.removeItem(key);
        this._cloudStorage.removeItem(key);
    }

    private async getItem<T> (key: string): Promise<T | null> {
        return (
            this._sessionStorage.getItem(key) ||
            this._localStorage.getItem(key) ||
            this._cookieStorage.getItem(key) ||
            this._cloudStorage.getItem(key)
        );
    }
}


export const gameDataService = new GameDataService(
    browserSessionStorage,
    browserLocalStorage,
    browserCookieStorage,
    cloudStorage
);
