import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { Loader } from 'shared/ui/loader';
import { GOOD_LUCK_BASE_URL } from 'shared/constants';
import { STATIC_IDS } from 'shared/constants/game-ids';
import { gameDataService } from 'services';
import { GoodLuckAppLoadable } from 'applications/good-luck';
import { TestQuizLoadable } from 'Games/TestQuiz';
import { SummerQuizLoadable } from 'Games/SummerQuiz';
import { MarlboroLoadable } from 'Games/Marlboro';
import { ParliamentLoadable } from 'Games/Parliament';
import { CountriesLoadable } from 'Games/Countries';
import { SurveyInteractiveGameLoadable } from 'Games/SurveyInteractiveGame';
import { ArchetypeLoadable } from 'Games/Archetype';
import { IqosLoadable } from 'Games/Iqos';
import { SurveyLoadable } from 'Games/Survey';
import { SurveyConstructorLoadable } from 'Games/SurveyConstructor';
import { SurveyConstructorIqosLoadable } from 'Games/SurveyConstructorIqos';
import { SurveyLoyaltyLoadable } from 'Games/SurveyLoyalty';
import { WinterShuffleLoadable } from 'Games/WinterShuffle';
import { WSMRLLoadable } from 'Games/WSMRL';
import { LoyaltyInfoLoadable } from 'LoyaltyInfo';


export const Router = () => {
    const [ gameDataSaved, setGameDataSaved ] = useState(false);

    useEffect(() => {
        gameDataService.saveGameData().then(setGameDataSaved);
    }, []);

    if (!gameDataSaved) return <Loader/>;

    return (
        <ErrorBoundary>
            <BrowserRouter>
                <Switch>
                    <Route path={ `/${ STATIC_IDS.TEST_QUIZ }` } component={ TestQuizLoadable }/>
                    <Route path={ `/${ STATIC_IDS.SUMMER_QUIZ }` } component={ SummerQuizLoadable }/>
                    <Route path={ `/${ STATIC_IDS.COUNTRIES }` } component={ CountriesLoadable }/>

                    <Route path={ `/${ STATIC_IDS.MARLBORO }` } component={ MarlboroLoadable }/>
                    <Route path={ `/${ STATIC_IDS.INTERACTIVE_GAME }` } component={ SurveyInteractiveGameLoadable }/>
                    <Route path={ `/${ STATIC_IDS.PARLIAMENT }` } component={ ParliamentLoadable }/>

                    <Route path={ `/${ STATIC_IDS.ARCHETYPE }` } component={ ArchetypeLoadable }/>
                    <Route path={ `/${ STATIC_IDS.IQOS }` } component={ IqosLoadable }/>
                    <Route path={ `/${ STATIC_IDS.WINTER_SHUFFLE_MB }` } component={ WSMRLLoadable }/>
                    <Route path={ `/${ STATIC_IDS.WINTER_SHUFFLE }` } component={ WinterShuffleLoadable }/>

                    <Route
                        path={ [
                            `/constructor/${ STATIC_IDS.GOOD_LUCK }`,
                            `/${ STATIC_IDS.GOOD_LUCK }`,
                            `${ GOOD_LUCK_BASE_URL }/:id`,
                        ] }
                        component={ GoodLuckAppLoadable }
                    />

                    <Route
                        path={ [
                            `/constructor/${ STATIC_IDS.LOYALTY_PARLIAMENT }`,
                            `/constructor/${ STATIC_IDS.LOYALTY_LM }`,
                            `/constructor/${ STATIC_IDS.LOYALTY_MPM }`,
                            `/constructor/${ STATIC_IDS.LOYALTY_MARLBORO }`,
                            `/${ STATIC_IDS.LOYALTY_PARLIAMENT }`,
                            `/${ STATIC_IDS.LOYALTY_LM }`,
                            `/${ STATIC_IDS.LOYALTY_MPM }`,
                            `/${ STATIC_IDS.LOYALTY_MARLBORO }`,
                        ] }
                        component={ SurveyLoyaltyLoadable }
                    />

                    <Route path={ '/brand/:name' } component={ LoyaltyInfoLoadable }/>
                    <Route path={ '/constructor-iqos/:id' } component={ SurveyConstructorIqosLoadable }/>
                    <Route path={ '/constructor/:id' } component={ SurveyConstructorLoadable }/>

                    <Route path='*' component={ SurveyLoadable }/>
                </Switch>
            </BrowserRouter>
        </ErrorBoundary>
    );
};
