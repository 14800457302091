/* eslint-disable no-unused-vars */
import { parseJson } from 'shared/lib/parse-json';
import { BreadcrumbLoggerInterface } from 'shared/lib/sentry/breadcrumb.logger.interface';
import { CloudStorageInterface } from '../cloud-storage.interface';


export class CloudStorageTelegram implements CloudStorageInterface {
    private readonly _storageType = 'cloud';
    private readonly _storage     = window.Telegram?.WebApp?.CloudStorage;
    private readonly _available   = this._storage && window.Telegram.WebApp.version >= '6.9';

    constructor (
        private readonly _breadcrumbLogger: BreadcrumbLoggerInterface
    ) {}

    async removeItem (key: string): Promise<void> {
        return await new Promise<void>((resolve, reject) => {
            if (!this._available) return resolve();

            try {
                this._storage.removeItem(key, (error) => {
                    if (error) {
                        reject(error);
                    } else {
                        this.logBreadcrumb(`Remove item "${ key }" from ${ this._storageType } storage`, { key });
                        resolve();
                    }
                });
            } catch (error) {
                resolve();
            }
        });
    }

    async getItem<T = any> (key: string): Promise<T | null> {
        return await new Promise<T | null>((resolve, reject) => {
            if (!this._available) return resolve(null);

            try {
                this._storage.getItem(key, (error, result) => {
                    if (error) {
                        reject(error);
                    } else {
                        if (!result) return resolve(null);
                        const data = parseJson(result);

                        this.logBreadcrumb(`Get item "${ key }" from ${ this._storageType } storage`, { key, data });

                        resolve(data);
                    }
                });
            } catch (error) {
                resolve(null);
            }
        });
    }

    async setItem<T = any> (key: string, value: T): Promise<void> {
        return await new Promise<void>((resolve, reject) => {
            if (!this._available) return resolve();

            const valueString = JSON.stringify(value);

            try {
                this._storage.setItem(key, valueString, (error) => {
                    if (error) {
                        reject(error);
                    } else {
                        this.logBreadcrumb(`Set item "${ key }" from ${ this._storageType } storage`, {
                            key,
                            data: value,
                        });
                        resolve();
                    }
                });
            } catch (error) {
                resolve();
            }
        });
    }

    clear () {}

    get length () {
        return 0;
    }

    key () {
        return '';
    }

    private logBreadcrumb (message: string, data: Record<string, any>): void {
        this._breadcrumbLogger.addBreadcrumb({
            message,
            data,
            category: 'storage',
            level   : 'info',
        });
    }
}
