/* eslint-disable no-unused-vars */
import { parseJson } from 'shared/lib/parse-json';


class CookieStorage {
    removeItem (key: string): void {
        this.setItem(key, null, new Date(0));
    }

    getItem<T = any> (key: string): T | null {
        const re      = this.getRegExp(key);
        const matches = document.cookie.match(re);
        return this.getCookieResult<T>(matches);
    }

    setItem<T = any> (key: string, value: T, expDate?: Date): void {
        expDate = expDate || new Date(Date.now() + 24 * 60 * 60 * 1000); // + 24h
        document.cookie = this.prepareCookie(key, value, expDate.toUTCString());
    }

    clear () {}

    get length () {
        return document.cookie.length;
    }

    key (index: number): string {
        return '';
    }

    private prepareCookie (key: string, value: any, expDate: string) {
        return `${ key }=${ encodeURIComponent(JSON.stringify(value)) };expires=${ expDate };path=/`;
    }

    private getRegExp (key: string) {
        return new RegExp(`(?:^|; )${key.replace(/([.$?*|{}()\[\]\\\/+^])/g, '\\$1')}=([^;]*)`);
    }

    private getCookieResult<T = any> (matches: RegExpMatchArray | null): T | null {
        if (matches === null) return null;

        const decodedCookie = decodeURIComponent(matches[1]);
        const parsedCookie  = parseJson(decodedCookie);

        if (parsedCookie === null) return null;

        return parsedCookie;
    }
}

export const cookieStorage = new CookieStorage();
