/* eslint-disable no-unused-vars */
import * as Sentry from '@sentry/react';
import { cookieStorage } from './cookie.storage';
import { BrowserStorageAbstract } from '../browser-storage.abstract';


export class BrowserCookieStorage extends BrowserStorageAbstract {
    constructor () {
        super(cookieStorage, Sentry);
        this.storageType = 'cookie';
    }
}
